/* !
 * jQuery index v1.0.0
 * Copyright 2019, Connecty Inc.
 */
(function($, FUNCTIONS){
	const a = 'is-active';
	const v = 'is-visible';

	const slider = () => {
		if($('.index-slide-notice').length) {
			$('.index-slide-notice').slick({
				autoplay: true,
				slidesToShow: 1,
				slidesToScroll: 1,
				loop: true,
				arrows: false,
				fade: true,
				dots: false
			});
		}
		if($('.index-slide02').length) {
			if($('.index-slide02-item').length == 1 ) {
				$('.index-slide02').slick({
					autoplay: true,
					slidesToShow: 2,
					slidesToScroll: 1,
					loop: true,
					arrows: true,
					prevArrow: '<a class="slick-prev" href="#"><svg class=""><use xlink:href="/common/svg/sprite.svg#arrow_left" /></svg></a>',
					nextArrow: '<a class="slick-next" href="#"><svg class=""><use xlink:href="/common/svg/sprite.svg#arrow_right" /></svg></a>',
					dots: true,
					centerMode: true,
					infinite: true,
					centerPadding: '15%',
					responsive: [{
						breakpoint: 768,
						settings: {
							centerMode: false,
							slidesToShow: 1,
						}
					}]
				});
				$('.index-slide02').find('.slick-active').addClass(a);
				$('.index-slide02').addClass('_item01');
			} else {
				$('.index-slide02').slick({
					autoplay: true,
					slidesToShow: 2,
					slidesToScroll: 1,
					loop: true,
					arrows: true,
					prevArrow: '<a class="slick-prev" href="#"><svg class=""><use xlink:href="/common/svg/sprite.svg#arrow_left" /></svg></a>',
					nextArrow: '<a class="slick-next" href="#"><svg class=""><use xlink:href="/common/svg/sprite.svg#arrow_right" /></svg></a>',
					dots: true,
					centerMode: true,
					infinite: true,
					centerPadding: '15%',
					responsive: [{
						breakpoint: 768,
						settings: {
							centerMode: false,
							slidesToShow: 1,
						}
					}]
				});
			}
			$('.index-slide02').find('.slick-center').addClass(a);
			$('.index-slide02').find('.slick-center').prev('.index-slide02-item').addClass(a);
			$('.index-slide02').on('afterChange', function(slick, currentSlide){
				$('.index-slide02').find('.index-slide02-item').removeClass(a);
				$('.index-slide02').find('.slick-center').addClass(a);
				$('.index-slide02').find('.slick-center').prev('.index-slide02-item').addClass(a);
			});
		}
	};
	const instagram = () => {
		if($('.js-instagram-list').length) {
			// instagram
			const instaBusinessId = '17841400535528282';
			const maxNumber = $(window).width() > 767 ? 8 : 8;
			const accessToken =
			'EAASGMUhr5HkBAN4eZBwEDKopv6YEWWw9h0l0peqCdfmvuR3DF8IzJakWXENeX8ZC2ZAxkdwZCJrSbC0xWZB87BmM2N9Dyk9UHQDriuM1WXmjPRSU5ocLfu76OTRR49Fp5UI7vxW2SSEduFCIYl7v71LRYvJZAOdVTN2gsQvkcEgt1KaZCorWhR6OsVTsE3ZCqbkZD';
			const instaJsonPath = `https://graph.facebook.com/v9.0/${instaBusinessId}?fields=name%2Cmedia.limit(${maxNumber})%7Bcaption%2Clike_count%2Cmedia_url%2Cthumbnail_url%2Cpermalink%2Ctimestamp%2Cusername%7D&access_token=${accessToken}`;
			console.log(instaJsonPath);
			let appendHTML = '';
			$.when(
				$.getJSON(instaJsonPath, (arr) => {
					const mediaData = arr.media.data;
					for (let i = 0; i < mediaData.length; i++) {
						appendHTML += '<div class="pure-u-1-4 pure-u-md-1-2 space-u-40-child">'+
						`<a href="${mediaData[i].permalink}" target="_blank" class="mod-link-zoom">`;
						if(!(mediaData[i].thumbnail_url)) {
							appendHTML += `<figure><img src="${mediaData[i].media_url}" class="width-u-100"></figure></a></div>`;
						} else {
							appendHTML += `<figure><img src="${mediaData[i].thumbnail_url}" class="width-u-100"></figure></a></div>`;
						}
						
					}
				})
			).done(() => {
				$('.js-instagram-list').append(appendHTML);
			});
		}
	};
	instagram();
	slider();
})(window.jQuery, window.FUNCTIONS);
$(window).on('load', function(){
	const indexMain = () => {
		if($('.index-slide01').length) {
			for(let i=0; i < $('.index-slide01-item-bg').length; i++) {
				let img = $('.index-slide01-item-bg').eq(i).data('img');
				let notice = 0;
				if($('.index-notice').length) {
					notice = $('.index-notice ._item').eq(0).innerHeight() + 15;
				}
				$('.index-slide01-item-bg').eq(i).css('background-image', 'url('+img+')');
				$('.index-slide01-item-bg').eq(i).height($(window).height() - $('.header').innerHeight() - notice);
			}
			if($('.index-slide01-item').length == 1 ) {
				$('.index-slide01').slick({
					autoplay: true,
					slidesToShow: 1,
					slidesToScroll: 1,
					loop: true,
					arrows: false,
					dots: false
				});
			} else {
				$('.index-slide01').slick({
					autoplay: true,
					slidesToShow: 1,
					slidesToScroll: 1,
					loop: true,
					arrows: false,
					dots: true
				});
			}
		}
	};
	const twitter = () => {
		const call = function(src, handler){
			let base = document.getElementsByTagName('script')[0];
			let obj = document.createElement('script');
			obj.async = true;
			obj.src= src;
			if(obj.addEventListener){
				obj.onload = function () {
				handler();

				}
			}else{
				obj.onreadystatechange = function () {
					if ('loaded' == obj.readyState || 'complete' == obj.readyState){
						obj.onreadystatechange = null;
						handler();
					}
				}
			}
			base.parentNode.insertBefore(obj,base);
		};
		call('https://platform.twitter.com/widgets.js', function(){
			var timerId = setInterval(function(){
				if($('iframe.twitter-timeline').length){
					$('.js-twitter-index').removeClass('di_none');
					let $widget = $('iframe.twitter-timeline');
					let $widgetContents = $widget.contents();
					$widgetContents.find('head').append('<style>.timeline-Tweet-author {margin-bottom:15px!important;}.timeline-Tweet-media,.timeline-Tweet-text,[data-scribe="component:actions"] {margin-left: 0!important;}.timeline-Widget{width:100%;max-width:100%;background: none!important;}.timeline-Header{display:none;}.timeline-Body{border:none!important;}.timeline-Footer{display:none;}.timeline-LoadMore{display:none;}.timeline-TweetList{display: flex;flex-flow: row wrap;align-content: flex-start;margin-top: -40px;margin-left: -40px;}.timeline-TweetList > li{padding-top: 40px;padding-left: 40px;display:none; width: 25%;border:none!important;box-sizing: border-box;}.timeline-TweetList > li:nth-child(1){display:block;}.timeline-TweetList > li:nth-child(2){display:block;}.timeline-TweetList > li:nth-child(3){display:block;}.timeline-TweetList > li:nth-child(4){display:block;}.timeline-Tweet-text{font-size: 12px!important;line-height: 1.5!important;}.TweetAuthor-name{font-size:14px!important; word-break: break-all!important;}.timeline-Tweet{background: #fff;border:1px solid #ccc;height:100%;box-sizing: border-box;}.TweetAuthor-link{margin: 0;width: 100%;overflow: hidden!important; display: block;}@media screen and (max-width: 1024px){.timeline-TweetList > li{width: 50%;}}@media screen and (max-width: 768px){.timeline-TweetList{margin-top: -6.25vw;margin-left: -6.25vw;}.timeline-TweetList > li{width: 100%;padding-top: 6.25vw;padding-left: 6.25vw;}}</style>');
					clearInterval(timerId);
				}
			}, 100);
		})
	};
	twitter();
	indexMain();
});